export const replaceKickOrBanReasonPlaceholderText = (template: string, reason: string, defaultText: string) => {
    //var template = strings.globalTexts.guestRestrictionMessage
    let contentString

    if (reason !== "") {
        contentString = template.split("{$reason}").join(reason)
    } else {
        contentString = defaultText
    }

    return contentString
}