import { Button } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../../../branding/branding"
import { useLanguageState } from "../../../../globalStates/LanguageState"
import { useVideoContext } from "../../../context/VideoContext"
import { LogoTitle } from "../logo/Type"
import { LogoUpload } from "../LogoUpload"

const ChooseButton = styled(Button) `
    width: 100%;
    margin-top: 5px;
    background-color: ${branding.audioVideoSettings.radioLogoButtonBackgroundColorActive} !important;
    color: ${branding.audioVideoSettings.radioLogoButtonTextColorActive} !important;
    border-color: ${branding.audioVideoSettings.radioLogoButtonBackgroundColorActive} !important;
    font-size: 12px;
    line-height: 12px;
    padding: 10px;
`

export function ChooseBackground() {
    const videoContext = useVideoContext()
    const strings = useLanguageState().getStrings().audioVideoSettings

    return (
        <>
            <Gallery />
            <LogoUpload onImageChanged={(url) => videoContext.setBackground("choose", url)}>
                <ChooseButton>{strings.radioChooseBackground}</ChooseButton>
            </LogoUpload>
        </>
    )
}

const Container = styled.div`
    height: 130px;
    overflow-y: scroll;
`

const GalleryImage = styled.div`
    background-position: center center;
    background-size: cover;
    height: 57px;
    width: 100%;
    margin: 5px 0;
    border-radius: 0.25rem;
    cursor: pointer;
`

function Gallery() {
    const videoContext = useVideoContext()
    const strings = useLanguageState().getStrings().audioVideoSettings

    if (!strings.galleryItemList || strings.galleryItemList.length === 0) return null

    return (
        <>
            <LogoTitle>{strings.galleryLabel}</LogoTitle>
            <Container>
                {strings.galleryItemList.map((item) => (
                    <GalleryImage
                        key={item}
                        style={{ backgroundImage: `url(${item})` }}
                        onClick={() => videoContext.setBackground("choose", item)}
                    />
                ))}
            </Container>
        </>
    )
}
